import style from "./style.module.css";
import Typography from "../../../../core/Typography";
import SearchInput from "../../../../core/Inputs/SearchInput";
import { useDevice } from "../../../customHooks";

export const SwapTitle = () => {
  let device = useDevice();
  const flexDirection = device === "mobile" ? "column" : "row";
  const alignItems = device === "mobile" ? "flex-start" : "initial";
  return (
    <div
      className={style.container_title}
      style={{ flexDirection, alignItems }}
    >
      <Typography type="h5" font="inter" colorStep={100} tagName="div">
        Latest swaps
      </Typography>
      <div>
        <SearchInput
          type="def"
          view="outlined"
          color="default"
          value=""
          placeholder="Search transactions"
          fill={device === "mobile"}
          style={device !== "mobile" && { width: "250px" }}
        />
      </div>
    </div>
  );
};
