import style from "./style.module.css";
import Color from "../../../../styles/color.module.css";
import cx from "classnames";
import { Icon } from "@blueprintjs/core";
import Typography from "../../../../core/Typography";
import Toaster from "../../../../core/Toast/Toaster";
import { useDevice } from "../../../customHooks";
import { useRef } from "react";

export const GridItem = ({ color, title, icon }) => {
  let device = useDevice();
  const gridWidth =
    device === "mobile" ? "109px" : device === "desktop" ? "222px" : "198px";
  const iconContainerWidth = device === "mobile" ? "64px" : "104px";
  const containerPadding = device === "mobile" ? "0.75rem 0" : "2.5rem 0";

  const DemoToast = {
    message: "Your app navigation!",
    icon: <Icon icon="info-sign" iconSize={24} />,
    action: {
      href: "https://gum.co/figma2react",
      target: "_blank",
      text: <strong>GET UI KIT</strong>
    }
  };

  const toastRef = useRef(DemoToast);

  return (
    <>
      <div
        className={cx(style["grid_item"], Color[color])}
        style={{ width: gridWidth, padding: containerPadding }}
        onClick={() => toastRef.current.show(DemoToast)}
      >
        <div
          className={style.item__icon}
          style={{
            width: iconContainerWidth,
            height: iconContainerWidth
          }}
        >
          <Icon icon={icon} iconSize={device === "mobile" ? 32 : 52} />
        </div>
        <Typography
          type="body"
          font="inter"
          color="primary"
          colorStep={100}
          tagName="div"
          large={device === "desktop"}
          small={device !== "desktop"}
          className={style.item__title}
        >
          {title}
        </Typography>
      </div>
      <Toaster
        type="def"
        view="raised"
        color="success"
        usePortal={false}
        position="bottom"
        ref={toastRef}
        maxToasts={2}
        withoutClose="false"
      />
    </>
  );
};
