import { Title } from "./Title";
import { Login } from "./Login";
import { Payment } from "./Payment";
import { Billing } from "./Billing";

export const Inputs = () => (
  <>
    <Title />
    <Login />
    <Payment />
    <Billing />
  </>
);
