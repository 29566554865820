import React from "react";

import { Label } from "@blueprintjs/core";
import Checkbox from "../../core/CheckBox";
import Slider from "../../core/Slider";

const PropsGroup = props => {
  const { value, setValue, size, setSize, margin } = props;
  return (
    <div>
      <Label>Props:</Label>
      <div className="demo-props">
        <Checkbox
          type="dense"
          color={value ? "primary" : "default"}
          checked={value}
          label="Set value 60%"
          onChange={() => setValue(value => !value)}
          margin={margin}
          fill
        />
        <Label>
          Slider size
          <Slider
            view="filled"
            color="default"
            labelStepSize={16}
            min={32}
            max={98}
            onChange={value => setSize(value)}
            stepSize={16}
            showTrackFill={true}
            value={size}
            className="demo-slider"
          />
        </Label>
      </div>
    </div>
  );
};

export default PropsGroup;
