import Typography from "../../../../core/Typography";
import style from "./style.module.css";

export const Info = () => (
  <div>
    <Typography type="caption" font="inter" colorStep={100} tagName="div">
      Free purchase for 24 hours
    </Typography>
    <Typography
      type="caption"
      font="inter"
      colorStep={50}
      tagName="div"
      style={{ marginBottom: "1rem" }}
    >
      Cancel within 24 hours of web app building and get a full powerup.
    </Typography>
    <Typography type="caption" font="inter" colorStep={50} tagName="div">
      By choosing Figma React UI kit, you agreed to{" "}
      <a
        href="https://gum.co/figma2react"
        title="Figma React kit"
        className={style.link}
      >
        Save time, Skip pixel routine
      </a>{" "}
      and{" "}
      <a
        href="https://gum.co/figma2react"
        title="Figma React kit"
        className={style.link}
      >
        Build web apps faster
      </a>
      . You also understand of importance to have on hand ready-to use templates
      by Setproduct.
    </Typography>
  </div>
);
