import React from "react";

import Menu from "../core/Menu";
import MenuItem from "../core/Menu/MenuItem";
import MenuDivider from "../core/Menu/MenuDivider";

export const AvaMenu = ({ dense }) => {
  const type = dense ? "dense" : "def";
  const viewMenu = "smooth";

  return (
    <Menu type={type} view={viewMenu}>
      <MenuItem type={type} view={viewMenu} text="Your profile" />
      <MenuItem type={type} view={viewMenu} text="Your projects" />
      <MenuItem type={type} view={viewMenu} text="Your stars" />

      <MenuDivider type={type} view={viewMenu} />
      <MenuItem type={type} view={viewMenu} text="Settings" note />
      <MenuItem type={type} view={viewMenu} text="Help" />
    </Menu>
  );
};
