import Chart from "react-google-charts";
import { options, data } from "./options";
import style from "./style.module.css";
import Tabs from "../../../../core/Tabs/Segmented";
import { Tab } from "@blueprintjs/core";
import Dropdown from "../../../../core/Dropdown";
import ExampleMenu from "./Menu";
import { useState } from "react";

export const BarChart = () => {
  const [isRange, setRange] = useState("Feb 3, 2021 – Mar 3, 2021");
  return (
    <div className={style.container}>
      <div className={style.caption_wrapper}>
        <Tabs view="raised" color="default" id="DemoTabs">
          <Tab id="1" title="Yearly" panel="Text" />
          <Tab id="2" title="Weekly" panel="Text" />
          <Tab id="3" title="Daily" panel="Text" />
        </Tabs>

        <Dropdown
          view="smooth"
          color="default"
          text={isRange}
          position="bottom-right"
          content={<ExampleMenu setRange={setRange} view="outlined" />}
          className={style.caption_dropdown}
        />
      </div>

      <Chart
        width={"100%"}
        height={"400px"}
        chartType="BarChart"
        graph_id="BarChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={options}
      />
    </div>
  );
};
