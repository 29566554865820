import Table from "../../../../core/Table/Table";
import { Head, Data } from "./data";

export const ChartTable = () => {
  return (
    <Table
      type="dense"
      view="flat"
      color="default"
      name={Head}
      data={Data}
      position={["left", "right", "right", "right", "right"]}
      pages={false}
      labelRowsPerPage="Show rows:"
      rowsPerPageOptions={[10, 20, 50]}
      defRows={10}
      checkboxes={false}
    />
  );
};
