import React from "react";
import Typography from "../core/Typography";
import * as LINKS from "../demo/constants/Constants";
import Button from "../core/Button";

export const DemoDrawer = () => {
  return (
    <div
      style={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"
      }}
    >
      <div>
        <Typography type="h4" font="inter" colorStep={100} tagName="div">
          This is sidebar
        </Typography>
        <Typography
          type="h6"
          font="inter"
          colorStep={50}
          tagName="div"
          style={{ marginBottom: "1.5rem" }}
        >
          Use our product to save time
        </Typography>
        <Typography type="body" font="inter" colorStep={100} tagName="div">
          <a
            href="https://setproduct.com/system"
            style={{ textDecoration: "none" }}
          >
            <Typography
              type="body"
              font="inter"
              color="primary"
              colorStep={70}
              tagName="div"
            >
              Setproduct Design System
            </Typography>
          </a>{" "}
          is a React-based UI toolkit for the web. Designed and well-organized
          in Figma and visually synchronized with React. Contains both Figma
          design filesand React components and 60 ready-to-use adaptive web app
          templates.
        </Typography>
      </div>
      <div>
        <a
          href={LINKS.BUY_LINK}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button
            type="card"
            view="smooth"
            color="primary"
            dense={false}
            text="Purchase Figma React UI kit"
            icon="shopping-cart"
            style={{ width: "100%", padding: "1rem", height: "auto" }}
          />
        </a>
      </div>
    </div>
  );
};
