import { useDevice } from "../../../customHooks";
import { Item } from "./Item";
import style from "./style.module.css";
import Toaster from "../../../../core/Toast/Toaster";
import { Icon } from "@blueprintjs/core";
import { useRef, useState } from "react";

export const Cards = () => {
  let device = useDevice();
  const flexDirection = device === "mobile" ? "column" : "row";
  const marginBottom = device === "mobile" ? "1rem" : 0;
  const marginRight = device === "mobile" ? 0 : "1rem";

  const DemoToast = {
    message: "Unlock full version",
    icon: <Icon icon="info-sign" iconSize={24} />,
    action: {
      href: "https://gum.co/figma2react",
      target: "_blank",
      text: <strong>RIGHT NOW</strong>
    }
  };
  const toastRef = useRef(DemoToast);

  return (
    <div className={style.cards_row} style={{ flexDirection }}>
      <Item
        title="Total Sales"
        value="$12,568"
        result="+25% better"
        color="primary_alt"
        style={{ marginBottom, marginRight }}
        onClick={() => toastRef.current.show(DemoToast)}
      />
      <Item
        title="Expenses"
        value="$4,697"
        result="+12% better"
        color="danger_alt"
        style={{ marginBottom, marginRight }}
        onClick={() => toastRef.current.show(DemoToast)}
      />
      <Item
        title="Gross Profit"
        value="$7,871"
        result="-18% drowdown"
        color="success_alt"
        down
        style={{ marginBottom }}
        onClick={() => toastRef.current.show(DemoToast)}
      />
      <Toaster
        type="def"
        view="filled"
        color="danger"
        usePortal={false}
        position="bottom"
        ref={toastRef}
        maxToasts={2}
        withoutClose="false"
      />
    </div>
  );
};
