import React from "react";
import FooterLink from "./components/FooterLink";
import appStyle from "./styles/style.module.css";
import * as LINKS from "./constants/Constants";

export default function Footer() {
  return (
    <>
      <div className={appStyle.footer_text}>
        <div className={appStyle.footer_break}>
          Made with Setproduct &nbsp;
          <a
            href={LINKS.SETPRODUCT_LINK}
            style={{
              color: "var(--blue50)",
              textDecoration: "underline"
            }}
          >
            Figma React UI kit
          </a>
        </div>
      </div>
      <div className={appStyle.footer_links}>
        <FooterLink
          href={LINKS.REACT_DOCS_LINK}
          value="React docs"
          icon="manual"
        />
        <FooterLink
          href={LINKS.FIGMA_DEMO_LINK}
          value="Figma Preview"
          icon="search-text"
        />
        <FooterLink
          href={LINKS.LICENSE_LINK}
          value="License"
          icon="manually-entered-data"
        />
        <FooterLink
          href={LINKS.BUY_LINK}
          value="Buy UI kit"
          icon="shopping-cart"
        />
      </div>
    </>
  );
}
