import Button from "../../../../core/Button";
import Dropdown from "../../../../core/Dropdown";

import style from "./style.module.css";
import { Caption } from "./Caption";
import { Inputs } from "./Inputs";
import { Info } from "./Info";
import { DropdownMenu } from "./DropdownMenu";
import Dialog from "../../../../core/Dialog";
import { useState } from "react";
import * as LINKS from "../../../constants/Constants";
import { useDevice } from "../../../customHooks";

export const Payment = () => {
  let device = useDevice();
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <Caption />
        <Dropdown
          view="outlined"
          color="default"
          text="Credit or Debit Card"
          position="bottom-left"
          content={<DropdownMenu />}
          className={style.dropdown}
        />
        <div className={style.line} />

        <Inputs />
        <Info />
        <Button
          view="smooth"
          color="default"
          text="Complete your order"
          fill={true}
          style={{ marginTop: "1.5rem" }}
          onClick={() => setOpen(true)}
        />
      </div>
      <Dialog
        view="raised"
        icon="info-sign"
        title="Ready to use templates"
        text={
          "Setproduct Design System is a React-based UI toolkit for the web. Designed and well-organized in Figma. Optimized for building complex data-dense interfaces for desktop and mobile applications. This digital product contains both Figma design files (FIG) and React components (ZIP) and 60 ready-to-use web app templates"
        }
        backdropOpacity={20}
        leftButton={
          <a
            href={LINKS.FIGMA_DEMO_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              type="default"
              view="outlined"
              dense={false}
              onClick={() => setOpen(false)}
              text="Preview in Figma"
            />
          </a>
        }
        rightButton={
          <a
            href={LINKS.BUY_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              type="default"
              view="filled"
              dense={false}
              text="Purchase for $258"
            />
          </a>
        }
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        className={device === "mobile" ? style.dialog : ""}
      />
    </div>
  );
};
