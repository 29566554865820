import Typography from "../../../../core/Typography";
import Breadcrumbs from "../../../../core/Breadcrumbs";
import { useDevice } from "../../../customHooks";

export const Title = () => {
  let device = useDevice();
  const CrumbsItem = [
    { href: "#", icon: "folder-close", text: "Program Files" },
    { href: "#", icon: "folder-close", text: "Common Files" },
    { href: "#", icon: "cube", text: "Figma React" },
    { href: "#", icon: "page-layout", text: "Templates" },
    {
      href: "#",
      icon: "panel-table",
      text: "Data Grid",
      current: true
    }
  ];
  return (
    <div style={{ margin: "1.5rem 1rem" }}>
      <Typography type="h4" font="inter" colorStep={100} tagName="div">
        Add Liquidity
      </Typography>
      <Typography
        type={device === "mobile" ? "body" : "h6"}
        font="inter"
        colorStep={50}
        tagName="div"
      >
        A data grid filled with various content
      </Typography>

      <div style={{ maxWidth: "640px", marginTop: "1.5rem" }}>
        <Breadcrumbs collapseFrom="start" items={CrumbsItem} />
      </div>
    </div>
  );
};
