import React, { useReducer } from "react";
import Typography from "../../../../../core/Typography";
import Chips from "../../../../../core/Chips";
import Checkbox from "../../../../../core/CheckBox";
import style from "./style.module.css";

const initialState = {
  a: true,
  b: true,
  c: false
};

const reducer = (state, action) => {
  return { ...state, [action.name]: !state[action.name] };
};

export const Email = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <div className={style.title}>
        <div>
          <Typography type="h6" font="inter" colorStep={100} tagName="div">
            Email notifications
          </Typography>
          <Typography
            type="body"
            font="inter"
            colorStep={50}
            tagName="div"
            //   className={style.desc}
          >
            Choose what types of emails to SPAM
          </Typography>
        </div>

        <Chips
          type="def"
          color="success"
          round={false}
          tag="Enable notifications"
          icon="notifications"
          active
          className={style.chips}
        />
      </div>

      <Checkbox
        type="def"
        color="primary"
        checked={state.a}
        label={
          <span>
            Product updates
            <span className={style.email_checkbox_secondline}>
              &nbsp;— receive news about the shit we serve.
            </span>
          </span>
        }
        disabled={false}
        fill={true}
        style={{ marginBottom: "0.5rem", marginTop: "1.5rem" }}
        onChange={() => dispatch({ name: "a" })}
      />
      <Checkbox
        type="def"
        color="primary"
        checked={state.b}
        label={
          <span>
            Unread notifications
            <span className={style.email_checkbox_secondline}>
              &nbsp;— receive a friendly bump when ne...
            </span>
          </span>
        }
        disabled={false}
        fill={true}
        style={{ marginBottom: "0.5rem" }}
        onChange={() => dispatch({ name: "b" })}
      />
      <Checkbox
        type="def"
        color="primary"
        checked={state.c}
        label={
          <span>
            Invite accepted
            <span className={style.email_checkbox_secondline}>
              &nbsp;— receive an email when someone acce...
            </span>
          </span>
        }
        disabled={false}
        fill={true}
        onChange={() => dispatch({ name: "c" })}
      />
    </>
  );
};
