import React, { useReducer } from "react";
import Typography from "../../../../../core/Typography";
import Dropdown from "../../../../../core/Dropdown";
import Switch from "../../../../../core/Switch";
import ExampleMenu from "./Menu";
import { useDevice } from "../../../../customHooks";
import style from "./style.module.css";

const initialState = {
  a: false,
  b: true
};
const reducer = (state, action) => {
  return { ...state, [action.name]: !state[action.name] };
};
export const Display = () => {
  let device = useDevice();
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <>
      <Typography
        type="h6"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ marginBottom: "1.5rem" }}
      >
        Display preferences
      </Typography>
      <div className={style.title}>
        <div className={style.title_wrapper}>
          <Typography type="body" font="inter" colorStep={100} tagName="div">
            Interface theme
          </Typography>
          <Typography
            type="body"
            small
            font="inter"
            colorStep={60}
            tagName="div"
          >
            Select your interface color scheme.
          </Typography>
        </div>

        <Dropdown
          view="raised"
          color="default"
          text="Dropdown"
          position="bottom-right"
          content={<ExampleMenu view="flat" dense />}
        />
      </div>
      <div className={style.divider} />
      <Switch
        type="def"
        view="outlined"
        iStyle
        color="success"
        checked={state.a}
        label={
          device === "mobile" ? "Reduce motion" : "Reduce motion and animations"
        }
        fill={true}
        helperText={
          device !== "mobile" &&
          "Reduce motion in the user interface by disabling animations."
        }
        leftPosition={true}
        onChange={() => dispatch({ name: "a" })}
        className={style.switch}
      />
      <div className={style.divider} />
      <Switch
        type="def"
        view="outlined"
        color="success"
        iStyle
        checked={state.b}
        label="Display full names"
        fill={true}
        helperText={
          device !== "mobile" &&
          "Configure your app to make other users visible"
        }
        leftPosition={true}
        onChange={() => dispatch({ name: "b" })}
        style={{ boxShadow: "none" }}
      />
    </>
  );
};
