import Chart from "react-google-charts";
import style from "./style.module.css";
import { pieOptions } from "./options";
import { Legend } from "./Legend";

const tooltipLayout = (label, value, color) => {
  return `<div class="${style.tooltip}" 
            style="--bg: var(--${color}5);
            --color: var(--${color}60);
            --shadow: var(--${color}Shadow2dp);
            "
            onmouseover="event.stopPropagation()"
            onmousemove="event.stopPropagation()"
            >
            <div class="${style.label}" >${label}</div>
            <div class="${style.value}" >${value}%</div>
          </div>`;
};

const data = [
  [
    "Language",
    "Your bestsellers",
    { type: "string", role: "tooltip", p: { html: true } }
  ],
  ["ReactJS", 35.3, tooltipLayout("ReactJS", 35.3, "indigo")],
  ["Angular", 27.8, tooltipLayout("Angular", 27.8, "orange")],
  ["XueJS", 5.8, tooltipLayout("XueJS", 5.8, "green")],
  ["Svelte", 7.3, tooltipLayout("Svelte", 7.3, "red")],
  ["VueJS", 23.8, tooltipLayout("VueJS", 23.8, "teal")]
];

export const PieChart = () => {
  return (
    <>
      <Chart
        chartType="PieChart"
        data={data}
        options={pieOptions}
        graph_id="PieChart"
        width={"100%"}
        height={"320px"}
        legend_toggle
      />
      <Legend />
    </>
  );
};
