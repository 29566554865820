import React from "react";
import Input from "../../../../core/Inputs/DefaultInput";

import style from "./style.module.css";

export const Inputs = () => {
  return (
    <>
      <div className={style.card_cvc_wrapper}>
        <Input
          type="text"
          view="outlined"
          color="default"
          label="First name"
          placeholder="e.g. John"
          value="John"
          clearButton
          fill
          className={style.card__exp}
        />
        <Input
          type="text"
          view="outlined"
          color="default"
          label="Last name"
          placeholder="e.g. Doe"
          value="Doe"
          clearButton
          fill
        />
      </div>
      <Input
        type="text"
        view="outlined"
        color="default"
        label="Address"
        placeholder="e.g. 123 Setproduct St."
        value="123 Setproduct St."
        clearButton
        fill
        className={style.text_input}
      />
      <Input
        type="text"
        view="outlined"
        color="default"
        placeholder="Suite / Floor / Apt."
        clearButton
        fill
        className={style.text_input}
      />
      <Input
        type="text"
        view="outlined"
        color="default"
        label="City"
        placeholder="City"
        value=" "
        clearButton
        fill
        className={style.text_input}
      />

      <div className={style.card_cvc_wrapper}>
        <Input
          type="text"
          view="outlined"
          color="default"
          label="State / Province"
          placeholder="State / Province"
          value=" "
          clearButton
          fill
          className={style.card__exp}
        />
        <Input
          type="text"
          view="outlined"
          color="default"
          label="Zip / Postal code"
          placeholder="Zip / Postal code"
          value=" "
          clearButton
          fill
        />
      </div>
    </>
  );
};
