import React from "react";
import Typography from "../../../../../core/Typography";
import style from "./style.module.css";

export const Badge = ({ value }) => {
  return (
    <Typography
      type="caption"
      font="inter"
      colorStep={100}
      tagName="div"
      className={style.badge}
    >
      {value}
    </Typography>
  );
};
