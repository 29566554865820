import React, { useState } from "react";
import Button from "../../../../core/Button";
import Ava from "./avatar.svg";
import style from "../style.module.css";
import Dialog from "../../../../core/Dialog";
import { useDevice } from "../../../customHooks";
import * as LINKS from "../../../constants/Constants";

export const Avatar = () => {
  let device = useDevice();
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={style.avatar}>
      <img src={Ava} alt="" />
      <Button
        type="circle"
        view="filled"
        color="default"
        icon="edit"
        className={style.button}
        onClick={() => setOpen(true)}
      />
      <Dialog
        view="raised"
        icon="info-sign"
        title="Ready to use templates"
        text={
          "Setproduct Design System is a React-based UI toolkit for the web. Designed and well-organized in Figma. Optimized for building complex data-dense interfaces for desktop and mobile applications. This digital product contains both Figma design files (FIG) and React components (ZIP) and 60 ready-to-use web app templates"
        }
        backdropOpacity={20}
        leftButton={
          <a
            href={LINKS.FIGMA_DEMO_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              type="default"
              view="outlined"
              dense={false}
              onClick={() => setOpen(false)}
              text="Preview in Figma"
            />
          </a>
        }
        rightButton={
          <a
            href={LINKS.BUY_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              type="default"
              view="filled"
              dense={false}
              text="Purchase for $258"
            />
          </a>
        }
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        className={device === "mobile" ? style.dialog : ""}
      />
    </div>
  );
};
