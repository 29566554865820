export const Data = [
  {
    id: 0,
    hasCaret: true,
    isExpanded: true,
    label: "Getting Started",
    childNodes: [
      {
        id: 1,
        label: "Introduction",
        link: "/docs/introduction"
      },
      {
        id: 2,
        label: "Installation",
        link: "/docs/installation"
      },
      {
        id: 3,
        label: "Implementation",
        link: "/docs/implementation"
      },
      {
        id: 4,
        label: "Principles",
        link: "/docs/principles"
      },
      {
        id: 5,
        label: "Templates",
        link: "/docs/templates"
      },
      {
        id: 6,
        label: "Colors",
        link: "/docs/colors"
      },
      {
        id: 7,
        label: "Icons",
        link: "/docs/icons"
      }
    ]
  },
  {
    id: 10,
    hasCaret: true,
    isExpanded: true,
    label: "Components",
    childNodes: [
      {
        id: 11,
        label: "Badge",
        link: "/components/badge"
      },
      {
        id: 12,
        label: "Breadcrumbs",
        link: "/components/breadcrumbs"
      },
      {
        id: 13,
        label: "Button",
        link: "/components/button"
      },
      {
        id: 14,
        label: "ButtonGroup",
        link: "/components/button-group"
      },
      {
        id: 15,
        label: "Callout",
        link: "/components/callout"
      },
      {
        id: 16,
        label: "Card",
        link: "/components/card"
      },
      {
        id: 17,
        label: "CheckBox",
        link: "/components/checkbox"
      },
      {
        id: 18,
        label: "Chips",
        link: "/components/chips"
      },
      {
        id: 19,
        label: "Counter",
        link: "/components/counter"
      },
      {
        id: 20,
        label: "DatePicker",
        link: "/components/date-picker"
      },
      {
        id: 21,
        label: "DateRangePicker",
        link: "/components/date-range-picker"
      },
      {
        id: 22,
        label: "Dialog",
        link: "/components/dialog"
      },
      {
        id: 23,
        label: "Divider",
        link: "/components/Divider"
      },
      {
        id: 24,
        label: "Drawer",
        link: "/components/drawer"
      },
      {
        id: 25,
        label: "Dropdown",
        link: "/components/dropdown"
      },
      {
        id: 26,
        label: "Hamburger",
        link: "/components/hamburger"
      },
      {
        id: 27,
        label: "Input Default",
        link: "/components/default-input"
      },
      {
        id: 28,
        label: "Input Search",
        link: "/components/search-input"
      },
      {
        id: 29,
        label: "Menu",
        link: "/components/menu"
      },
      {
        id: 30,
        label: "Navbar",
        link: "/components/navbar"
      },
      {
        id: 31,
        label: "Progress Bar",
        link: "/components/progress-bar"
      },
      {
        id: 32,
        label: "Radio Button",
        link: "/components/radio"
      },
      {
        id: 33,
        label: "Slider",
        link: "/components/slider"
      },
      {
        id: 34,
        label: "Spinner",
        link: "/components/spinner"
      },
      {
        id: 35,
        label: "Switch",
        link: "/components/switch"
      },
      {
        id: 36,
        label: "Table",
        link: "/components/table"
      },
      {
        id: 37,
        label: "Tab Default",
        link: "/components/tabs"
      },
      {
        id: 38,
        label: "Tab Segmented",
        link: "/components/tabs-segmented"
      },
      {
        id: 39,
        label: "TimePicker",
        link: "/components/time-picker"
      },
      {
        id: 40,
        label: "Toaster",
        link: "/components/toast"
      },
      {
        id: 41,
        label: "Tooltip",
        link: "/components/tooltip"
      },
      {
        id: 42,
        label: "Tree",
        link: "/components/tree"
      },
      {
        id: 43,
        label: "Typography",
        link: "/components/typography"
      }
    ]
  },
  {
    id: 60,
    hasCaret: true,
    isExpanded: true,
    label: "WebApp demo",
    childNodes: [
      {
        id: 61,
        label: "Home",
        link: "/templates/home"
      },
      {
        id: 62,
        label: "Dashboard",
        link: "/templates/dashboard"
      },
      {
        id: 63,
        label: "Data grid",
        link: "/templates/data-grid"
      },
      {
        id: 64,
        label: "Profile",
        link: "/templates/profile"
      },
      {
        id: 65,
        label: "Inputs",
        link: "/templates/inputs"
      }
    ]
  }
];
