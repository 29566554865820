export { default as DEMO_BUTTON } from "../Button";
export { default as DEMO_CHECKBOX } from "../Checkbox";
export { default as DEMO_RADIO } from "../Radio";
export { default as DEMO_SWITCH } from "../Switch";
export { default as DEMO_CHIPS } from "../Chips";
export { default as DEMO_TREE } from "../Tree";
export { default as DEMO_TOOLTIP } from "../Tooltip";
export { default as DEMO_CALLOUT } from "../Callout";
export { default as DEMO_MENU } from "../Menu";
export { default as DEMO_PROGRESS_BAR } from "../ProgressBar";
export { default as DEMO_SLIDER } from "../Slider";
export { default as DEMO_SPINNER } from "../Spinner";
export { default as DEMO_TOAST } from "../Toast";
export { default as DEMO_NAVBAR } from "../Navbar";
export { default as DEMO_TABS } from "../Tabs";
export { default as DEMO_DEFAULT_INPUT } from "../DefaultInput";
export { default as DEMO_SEARCH_INPUT } from "../SearchInput";
export { default as DEMO_TABLE } from "../Table";
export { default as DEMO_CARD } from "../Card";
export { default as DEMO_COUNTERS } from "../Counter";
export { default as DEMO_TYPOGRAPHY } from "../Typography";
export { default as DEMO_DRAWER } from "../Drawer";
export { default as DEMO_BREADCRUMBS } from "../Breadcrumbs";
export { default as DEMO_DIVIDER } from "../Divider";
export { default as DEMO_TABS_SEGMENTED } from "../TabsSegmented";
export { default as DEMO_DIALOG } from "../Dialog";
export { default as DEMO_HAMBURGER } from "../Hamburger";
export { default as DEMO_BUTTON_GROUP } from "../ButtonGroup";
export { default as DEMO_TIME_PICKER } from "../DateTime/TimePicker";
export { default as DEMO_DATE_PICKER } from "../DateTime/DatePicker";
export { default as DEMO_DATE_RANGE_PICKER } from "../DateTime/DateRangePicker";
export { default as DEMO_DROPDOWN } from "../Dropdown";
export { default as DEMO_BADGE } from "../Badge";

export { default as DEMO_DOCS_COLORS } from "../docs/colors/demo";
export { default as DEMO_DOCS_ICONS } from "../docs/icons/demo";
export { default as DEMO_DOCS_INTRO } from "../docs/Introduction/demo";
export { default as DEMO_DOCS_INSTALL } from "../docs/Installation/demo";
export { default as DEMO_DOCS_PRINCIP } from "../docs/Principles/demo";
export { default as DEMO_DOCS_IMPLEMENT } from "../docs/Implementation/demo";
export { default as DEMO_DOCS_TEMPLATE } from "../docs/Template/demo";

export { Home as DEMO_TEMP_HOME } from "../../template/pages/Home";
export {
  Dashboard as DEMO_TEMP_DASHBOARD
} from "../../template/pages/Dashboard";
export { Grid as DEMO_TEMP_GRID } from "../../template/pages/Grid";
export { Profile as DEMO_TEMP_PROFILE } from "../../template/pages/Profile";
export { Inputs as DEMO_TEMP_INPUTS } from "../../template/pages/Inputs";

export const TEMP_HOME = "/templates/home";
export const TEMP_DASHBOARD = "/templates/dashboard";
export const TEMP_GRID = "/templates/data-grid";
export const TEMP_PROFILE = "/templates/profile";
export const TEMP_INPUTS = "/templates/inputs";

export const HOME = "/";
export const BUTTON = "/components/button";
export const CHECKBOX = "/components/checkbox";
export const RADIO = "/components/radio";
export const SWITCH = "/components/switch";
export const CHIPS = "/components/chips";
export const TREE = "/components/tree";
export const TOOLTIP = "/components/tooltip";
export const CALLOUT = "/components/callout";
export const MENU = "/components/menu";
export const PROGRESS_BAR = "/components/progress-bar";
export const SLIDER = "/components/slider";
export const SPINNER = "/components/spinner";
export const TOAST = "/components/toast";
export const NAVBAR = "/components/navbar";
export const TABS = "/components/tabs";
export const DEFAULT_INPUT = "/components/default-input";
export const SEARCH_INPUT = "/components/search-input";
export const TABLE = "/components/table";
export const CARD = "/components/card";
export const COUNTERS = "/components/counter";
export const TYPOGRAPHY = "/components/typography";
export const DRAWER = "/components/drawer";
export const DOCS_COLORS = "/docs/colors";
export const DOCS_ICONS = "/docs/icons";
export const DOCS_INTRO = "/docs/introduction";
export const DOCS_INSTALL = "/docs/installation";
export const DOCS_PRINCIP = "/docs/principles";
export const DOCS_IMPLEMENT = "/docs/implementation";
export const DOCS_TEMPLATE = "/docs/templates";
export const BREADCRUMBS = "/components/breadcrumbs";
export const DIVIDER = "/components/divider";
export const TABS_SEGMENTED = "/components/tabs-segmented";
export const DIALOG = "/components/dialog";
export const HAMBURGER = "/components/hamburger";
export const BUTTON_GROUP = "/components/button-group";
export const TIME_PICKER = "/components/time-picker";
export const DATE_PICKER = "/components/date-picker";
export const DATE_RANGE_PICKER = "/components/date-range-picker";
export const DROPDOWN = "/components/dropdown";
export const BADGE = "/components/badge";
