import Typography from "../../../../core/Typography";
import { useDevice } from "../../../customHooks";

export const Caption = () => {
  let device = useDevice();
  return (
    <>
      <Typography
        type={device === "mobile" ? "h6" : "h5"}
        font="inter"
        colorStep={100}
        tagName="div"
      >
        Please, log in here
      </Typography>
      <Typography
        type={device === "mobile" ? "caption" : "body"}
        font="inter"
        colorStep={50}
        tagName="div"
        large={device !== "mobile"}
      >
        Use this widget for your webapp
      </Typography>
    </>
  );
};
