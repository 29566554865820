import React, { useReducer, useRef } from "react";
import Typography from "../../../../core/Typography";
import Button from "../../../../core/Button";
import style from "../style.module.css";

import { Label } from "./Label";
import { Avatar } from "./Avatar";
import Toaster from "../../../../core/Toast/Toaster";
import { Icon } from "@blueprintjs/core";

const initialState = {
  a: false,
  b: false
};

const DemoToast = {
  message: "Unlock full version",
  icon: <Icon icon="info-sign" iconSize={24} />,
  action: {
    href: "https://gum.co/figma2react",
    target: "_blank",
    text: <strong>START NOW</strong>
  }
};

const reducer = (state, action) => {
  return { ...state, [action.name]: !state[action.name] };
};

export const Sidebar = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const toastRef = useRef(DemoToast);

  const handleClick = value => {
    dispatch({ name: value });
    toastRef.current.show(DemoToast);
  };

  return (
    <div className={style.sidebar}>
      <Avatar />
      <div>
        <Typography
          type="h5"
          font="inter"
          colorStep={100}
          tagName="div"
          className={style.desc}
        >
          Floyd Pixelcrafer
        </Typography>
        <Typography
          type="h6"
          font="inter"
          colorStep={50}
          tagName="div"
          className={style.desc}
        >
          Senior UX Designer
        </Typography>
        <Typography
          type="body"
          font="inter"
          colorStep={100}
          tagName="div"
          className={style.desc}
        >
          Creator of Figma React Ui kit. Author of a «Design components book».
          Dad of a 15BLN pixels.
        </Typography>
      </div>
      <div className={style["buttons-group"]}>
        <Button
          type="action"
          view="smooth"
          color="success"
          icon="tick"
          fill={true}
          text="Following"
          active={state.a}
          onClick={() => handleClick("a")}
        />
        <Button
          type="action"
          view="smooth"
          color="primary"
          icon="chat"
          fill={true}
          text="Message"
          style={{ margin: "1rem 0" }}
          active={state.b}
          onClick={() => handleClick("b")}
        />
        <Label
          text="Block or Report user"
          color="warning"
          icon="blocked-person"
        />

        <div className={style.divider} />
        <div>
          <Label text="Setproduct Inc." icon="office" />
          <Label text="24 participants" icon="briefcase" />
          <Label text="Palo Alto, California" icon="map-marker" />
          <Label text="setproduct.com" icon="link" color="primary" />
        </div>
      </div>
      <Toaster
        type="def"
        view="filled"
        color="danger"
        usePortal={false}
        position="bottom"
        ref={toastRef}
        maxToasts={2}
        withoutClose="false"
      />
    </div>
  );
};
