import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import styled from "styled-components";

import Button from "../core/Button";
import Navbar from "../core/Navbar";
import NavbarGroup from "../core/Navbar/Group.jsx";
import NavbarHeading from "../core/Navbar/Heading.jsx";
import Typography from "../core/Typography";
import Tooltip from "../core/Tooltip";
import { AvaMenu } from "./AvaMenu";
import Dropdown from "../core/Dropdown";
import Drawer from "../core/Drawer";

import DemoAppStyle from "./styles/style.module.css";
import ava from "./avatar.svg";
import * as LINKS from "./constants/Constants";

import { SidebarStateContext } from "./context/SidebarContext";
import { DemoDrawer } from "./DemoDrawer";
import { DemoDrawerLeft } from "./DemoDrawerLeft";

const LogoText = styled.span`
  color: white;
  margin-left: 0.75rem;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: var(--color100);
  vertical-align: middle;
`;
const LogoTextMobile = styled(LogoText)`
  background: #f285aa;
  border-radius: 4px;
  font-size: 20px;
  margin-left: 0.5rem;
  padding: 0.5rem 0.75rem;
  color: var(--white);
`;

// &:hover {
//   background: var(--color5) !important;
// }
// &:not([class*="focused"]):not(:hover) {
//   background: transparent !important;
// }
const NavButton = styled(Button)``;

const AppBar = props => {
  const { device, location } = props;

  const isMobile = device === "mobile" || device === "tablet";
  const [isOpen, setOpen] = useState(false);
  const [isOpenLeft, setOpenLeft] = useState(false);

  return (
    <SidebarStateContext.Consumer>
      {({ leftSidebarState, rightSidebarState, setSidebarState }) => (
        <React.Fragment>
          <div className={DemoAppStyle.props_button}>
            <Button
              type={isMobile ? "circle" : "action"}
              view="filled"
              icon={"dollar"}
              color="danger_alt"
              text={!isMobile && "Get started"}
              onClick={() => setOpen(true)}
            />
            <Drawer isOpen={isOpen} onClose={() => setOpen(!isOpen)}>
              <DemoDrawer />
            </Drawer>
          </div>
          <Drawer
            isOpen={isOpenLeft}
            onClose={() => setOpenLeft(!isOpenLeft)}
            position="left"
            style={{
              background: "var(--blue0)",
              boxShadow: "var(--blueShadow8dp)"
            }}
            hasBackdrop={false}
          >
            <DemoDrawerLeft />
          </Drawer>
          <Navbar type={device} view="raised" color="default" fixedToTop>
            <NavbarGroup
              style={device === "mobile" ? { marginTop: "0.5rem" } : {}}
            >
              <NavbarHeading>
                <Button
                  type={isMobile ? "icon" : "circle"}
                  view="smooth"
                  color="default"
                  icon="menu"
                  dense
                  onClick={() =>
                    isMobile
                      ? props.setOpenSidebar(item => ({
                          ...item,
                          left: !item.left
                        }))
                      : setOpenLeft(true)
                  }
                />
                {isMobile ? (
                  <LogoTextMobile>R</LogoTextMobile>
                ) : (
                  <LogoText>React UI kit</LogoText>
                )}
              </NavbarHeading>
            </NavbarGroup>
            {/**BOTTONS GROUP */}
            <NavbarGroup align="center">
              <Link to="/templates/home" style={{ textDecoration: "none" }}>
                <NavButton
                  dense={isMobile && true}
                  view="flat"
                  color="primary"
                  text="Home"
                  device={device}
                  active={location.pathname === "/templates/home"}
                  style={{ textDecoration: "none" }}
                />
              </Link>
              <Link to="/templates/dashboard">
                <NavButton
                  dense={isMobile && true}
                  view="flat"
                  color="primary"
                  text="Dashboard"
                  device={device}
                  active={location.pathname === "/templates/dashboard"}
                />
              </Link>
              <Link to="/templates/data-grid">
                <NavButton
                  dense={isMobile && true}
                  view="flat"
                  color="primary"
                  text="Data grid"
                  device={device}
                  active={location.pathname === "/templates/data-grid"}
                  style={{ whiteSpace: "nowrap" }}
                />
              </Link>

              <Link to="/templates/profile">
                <NavButton
                  dense={isMobile && true}
                  view="flat"
                  color="primary"
                  text="Profile"
                  device={device}
                  active={location.pathname === "/templates/profile"}
                />
              </Link>

              <Link to="/templates/inputs">
                <NavButton
                  dense={isMobile && true}
                  view="flat"
                  color="primary"
                  text="Inputs"
                  device={device}
                  active={location.pathname === "/templates/inputs"}
                />
              </Link>
            </NavbarGroup>

            {/** */}
            <NavbarGroup align="right" className={DemoAppStyle.navbar_ava}>
              {device === "desktop" && (
                <div className={DemoAppStyle.ava_name}>
                  <Typography
                    type="body"
                    font="inter"
                    colorStep={100}
                    tagName="div"
                  >
                    Floyd Pixelcrafer
                  </Typography>
                  <Typography
                    type="body"
                    small
                    font="inter"
                    colorStep={60}
                    tagName="div"
                  >
                    Sr. UX architect
                  </Typography>
                </div>
              )}
              <Dropdown
                text={
                  <img
                    src={ava}
                    alt=""
                    style={!isMobile ? { marginRight: "0.75rem" } : {}}
                  />
                }
                position="bottom-left"
                content={<AvaMenu />}
                className={DemoAppStyle.ava_menu}
              />

              <a href={LINKS.BUY_LINK} style={{ textDecoration: "none" }}>
                <Tooltip content="For Figma & React" position="bottom">
                  <Button
                    dense={isMobile && true}
                    view="outlined"
                    color="default"
                    icon={device !== "mobile" && "shopping-cart"}
                    text={
                      <Typography
                        type="button"
                        font="inter"
                        colorStep={70}
                        tagName="div"
                      >
                        Purchase
                      </Typography>
                    }
                  />
                </Tooltip>
              </a>
            </NavbarGroup>
          </Navbar>
        </React.Fragment>
      )}
    </SidebarStateContext.Consumer>
  );
};

export default withRouter(AppBar);
