import React from "react";
import Callout from "../../../../core/Callout";
import { Icon } from "@blueprintjs/core";

export const GoPro = () => {
  return (
    <Callout
      type="def"
      view="smooth"
      color="danger"
      title="Time to go PRO!"
      icon={<Icon icon="offline" iconSize={24} />}
      style={{ marginBottom: "40px" }}
    >
      Setproduct Design System is the unlike React UI kit. Including Figma
      design sources+ React components based on enhanced and restyled
      BlueprintJS library this system saves «a penny»
    </Callout>
  );
};
