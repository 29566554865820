import React from "react";
import Table from "../../../../core/Table/Table";
import style from "./style.module.css";
import { data } from "./data";
import { SwapTitle } from "./Title";

export const SwapTable = () => {
  return (
    <>
      <SwapTitle />
      <Table
        type="def"
        view="flat"
        color="default"
        name={Head}
        data={data}
        position={["left"]}
        checkboxes={false}
        paperClassName={style.container}
      />
    </>
  );
};

const Head = [
  { id: "desc", label: "Description" },
  { id: "value", label: "Total Value" },
  { id: "coin", label: "Coin" },
  { id: "ticket", label: "Ticker" },
  { id: "address", label: "Address" },
  { id: "time", label: "Time" }
];
