import React from "react";
import Tabs from "../../../../../core/Tabs";
import { Tab } from "@blueprintjs/core";
import style from "./style.module.css";
import { Badge } from "./Badge";

export const TabsDemo = () => {
  return (
    <div className={style.tabs_wrapper}>
      <Tabs
        type="dense"
        view="flat"
        color="primary"
        id="DemoTabs"
        fill
        className={style.tablist}
      >
        <Tab id="1" title="Overview" />
        <Tab
          id="2"
          title={
            <div className={style.tab_title}>
              <Badge value="256" />
              Repositories
            </div>
          }
        />
        <Tab
          id="3"
          title={
            <div className={style.tab_title}>
              <Badge value="12K" />
              Stars
            </div>
          }
        />
        <Tab
          id="4"
          title={
            <div className={style.tab_title}>
              <Badge value="128K" />
              Followers
            </div>
          }
        />
        <Tab
          id="5"
          title={
            <div className={style.tab_title}>
              <Badge value="32" />
              Following
            </div>
          }
        />
      </Tabs>
    </div>
  );
};
