import React from "react";
import { Grid } from "./Grid";
import { Title } from "./Title";
import { Cards } from "./Cards";

export const Home = () => (
  <>
    <Title />
    <Grid />
    <Cards />
  </>
);
