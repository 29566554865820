import React from "react";
import Typography from "../../../../core/Typography";
import style from "./style.module.css";
import { useDevice } from "../../../customHooks";

export const Stats = () => {
  let device = useDevice();
  const flexDir = device === "mobile" ? "column" : "row";

  return (
    <div className={style.container}>
      <Typography
        type="h6"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ margin: "0 1rem 1rem 1rem" }}
      >
        Statistics
      </Typography>
      <div className={style.separator} />
      <div className={style.data} style={{ flexDirection: flexDir }}>
        {/* <div className={style.data}> */}
        <div className={style.item}>
          <Typography type="body" font="inter" colorStep={60} tagName="div">
            Total Liquidity
          </Typography>
          <div style={{ display: "flex" }}>
            <Typography
              type="body"
              font="inter"
              colorStep={60}
              tagName="div"
              large
            >
              $&nbsp;
            </Typography>
            <Typography
              type="body"
              font="inter"
              colorStep={100}
              tagName="div"
              large
            >
              1,721,161,452
            </Typography>
          </div>
        </div>
        <div className={style.item}>
          <Typography type="body" font="inter" colorStep={60} tagName="div">
            Price SET
          </Typography>
          <div style={{ display: "flex" }}>
            <Typography
              type="body"
              font="inter"
              colorStep={60}
              tagName="div"
              large
            >
              $&nbsp;
            </Typography>
            <Typography
              type="body"
              font="inter"
              colorStep={100}
              tagName="div"
              large
            >
              256.45
            </Typography>
            <Typography
              type="body"
              font="inter"
              color="success"
              colorStep={60}
              tagName="div"
              small
              style={{ alignSelf: "center" }}
            >
              &nbsp;+12.44%
            </Typography>
          </div>
        </div>
        {/* </div> */}
        {/* <div className={style.data}> */}
        <div className={style.item}>
          <Typography type="body" font="inter" colorStep={60} tagName="div">
            Volume (24h)
          </Typography>
          <div style={{ display: "flex" }}>
            <Typography
              type="body"
              font="inter"
              colorStep={60}
              tagName="div"
              large
            >
              $&nbsp;
            </Typography>
            <Typography
              type="body"
              font="inter"
              colorStep={100}
              tagName="div"
              large
            >
              256.45
            </Typography>
            <Typography
              type="body"
              font="inter"
              color="success"
              colorStep={60}
              tagName="div"
              small
              style={{ alignSelf: "center" }}
            >
              &nbsp;+12.44%
            </Typography>
          </div>
        </div>
        <div className={style.item}>
          <Typography
            type="body"
            font="inter"
            colorStep={60}
            tagName="div"
            className={style.item}
          >
            Volume (24h)
          </Typography>
          <Typography
            type="body"
            font="inter"
            colorStep={100}
            tagName="div"
            large
          >
            62.86%
          </Typography>
        </div>
      </div>
    </div>
    // </div>
  );
};
