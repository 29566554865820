import React from "react";
import { CoinsTable } from "./CoinsTable";
import { Title } from "./Title";
import { Stats } from "./Stats";
import { TabsLayout } from "./Tabs";
import { SwapTable } from "./SwapTable";

export const Grid = () => {
  return (
    <>
      <Title />
      <div style={{ padding: "0 1rem" }}>
        <Stats />
        <TabsLayout />
        <CoinsTable />
        <SwapTable />
      </div>
    </>
  );
};
