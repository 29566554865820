import Card from "../../../../core/Card";
import Typography from "../../../../core/Typography";
import { Icon } from "@blueprintjs/core";
import cx from "classnames";
import style from "./style.module.css";
import Color from "../../../../styles/color.module.css";

export const Item = ({
  down,
  title,
  value,
  result,
  color = "default",
  ...props
}) => {
  return (
    <Card view="smooth" interactive color={color} {...props}>
      <div style={{ margin: "1rem", display: "inline-block" }}>
        <Typography
          type="h6"
          large
          style={{
            paddingBottom: "1rem"
          }}
          colorStep={100}
        >
          {title}
        </Typography>
        <Typography type="h3" large colorStep={60} color={color}>
          {value}
        </Typography>
        <Typography type="body" font="inter" style={{ display: "flex" }}>
          <Icon
            icon={down ? "caret-down" : "caret-up"}
            iconSize={24}
            className={down ? style.down : style.up}
          />
          {result}
        </Typography>
      </div>
    </Card>
  );
};
