import Typography from "../../../../core/Typography";
import { ChartTable } from "./Table";
import { PieChart } from "./Chart";
import style from "./style.module.css";
import { useDevice } from "../../../customHooks";

export const PieChartLayout = () => {
  let device = useDevice();
  const flexDir = device === "tablet" || device === "mobile" ? "column" : "row";

  return (
    <>
      <Typography
        type="h5"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ marginLeft: "1rem" }}
      >
        Your bestsellers
      </Typography>
      <div className={style.container} style={{ flexDirection: flexDir }}>
        <div className={style.wrapper}>
          <PieChart />
        </div>
        <div className={style.table_wrapper}>
          <ChartTable />
        </div>
      </div>
    </>
  );
};
