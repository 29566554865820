import Dropdown from "../../../../core/Dropdown";
import Typography from "../../../../core/Typography";
import Card from "../../../../core/Card";
import { useDevice } from "../../../customHooks";
import { Icon } from "@blueprintjs/core";
import { DropdownMenu } from "./Menu";
import { Item } from "./Item";
import style from "./style.module.css";
import { useRef, useState } from "react";
import Toaster from "../../../../core/Toast/Toaster";
import Dialog from "../../../../core/Dialog";
import Button from "../../../../core/Button";
import * as LINKS from "../../../constants/Constants";

export const Cards = () => {
  let device = useDevice();
  const flexDir = device === "mobile" ? "column" : "row";
  const marginBottom = device === "mobile" ? "1rem" : 0;

  const DemoToast = {
    message: "Cards-based subnavigation",
    icon: <Icon icon="info-sign" iconSize={24} />,
    action: {
      href: "https://gum.co/figma2react",
      target: "_blank",
      text: <strong>START NOW</strong>
    }
  };
  const toastRef = useRef(DemoToast);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className={style.container}>
        <Typography type="h5" font="inter" colorStep={100} tagName="div">
          Cards navigation
        </Typography>

        <div>
          <Dropdown
            view="outlined"
            text="Dropdown"
            position="bottom-left"
            content={<DropdownMenu view="outlined" />}
          />
        </div>
      </div>
      <div className={style.cards_row} style={{ flexDirection: flexDir }}>
        <Item
          icon="inbox"
          title="Inbox"
          style={{ marginBottom: marginBottom }}
          onClick={() => toastRef.current.show(DemoToast)}
        />
        <Item
          icon="add-to-folder"
          title="Send message"
          style={{ marginBottom: marginBottom }}
          onClick={() => toastRef.current.show(DemoToast)}
        />
        <Item
          icon="settings"
          title="General"
          style={{ marginBottom: marginBottom }}
          onClick={() => toastRef.current.show(DemoToast)}
        />
      </div>
      <div className={style.cards_row_last}>
        <Item
          icon="annotation"
          title="Annotations"
          onClick={() => toastRef.current.show(DemoToast)}
        />
        <Item
          icon="database"
          title="Database"
          onClick={() => toastRef.current.show(DemoToast)}
        />
      </div>
      <div className={style.cards_row} style={{ paddingBottom: "6rem" }}>
        <Card
          view="raised"
          color="primary"
          interactive
          style={device === "mobile" ? { flexGrow: "1" } : null}
          onClick={() => setOpen(true)}
        >
          <div className={style.card_row_add}>
            <div className={style.item__icon}>
              <Icon icon="add" iconSize={40} />
            </div>
            <Typography type="body" color="primary" colorStep={70}>
              Add new item
            </Typography>
          </div>
        </Card>
      </div>
      <Toaster
        type="def"
        view="filled"
        color="danger"
        usePortal={false}
        position="bottom"
        ref={toastRef}
        maxToasts={2}
        withoutClose="false"
      />
      <Dialog
        view="raised"
        icon="info-sign"
        title="Ready to use templates"
        text={
          "Setproduct Design System is a React-based UI toolkit for the web. Designed and well-organized in Figma. Optimized for building complex data-dense interfaces for desktop and mobile applications. This digital product contains both Figma design files (FIG) and React components (ZIP) and 60 ready-to-use web app templates"
        }
        backdropOpacity={20}
        leftButton={
          <a
            href={LINKS.FIGMA_DEMO_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              type="default"
              view="outlined"
              dense={false}
              onClick={() => setOpen(false)}
              text="Preview in Figma"
            />
          </a>
        }
        rightButton={
          <a
            href={LINKS.BUY_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              type="default"
              view="filled"
              dense={false}
              text="Purchase"
            />
          </a>
        }
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        className={device === "mobile" ? style.dialog : ""}
      />
    </>
  );
};
