import React from "react";
import { GridItem } from "./GridItem";
import style from "./style.module.css";

export const Grid = () => {
  return (
    <div className={style.layout}>
      <GridItem title="Event Marketing" color="primary" icon="send-to-graph" />
      <GridItem
        title="Build and Deploy Digital Assets"
        color="success"
        icon="build"
      />
      <GridItem title="Settings" color="danger_alt" icon="cog" />
      <GridItem
        title="Usability Pigs Inc. Management"
        color="warning_alt"
        icon="bank-account"
      />

      <GridItem
        title="Stocks Financial Briefcase"
        color="success_alt"
        icon="briefcase"
      />
      <GridItem
        title="AI Powered Layout Generator"
        color="warning"
        icon="clean"
      />
      <GridItem title="Administrator Roles" color="primary_alt" icon="people" />
      <GridItem title="Use React UI kit" color="danger" icon="cloud-download" />
    </div>
  );
};
