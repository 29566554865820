import React, { useReducer } from "react";
import Typography from "../../../../core/Typography";
import Switch from "../../../../core/Switch";
import style from "../style.module.css";

const initialState = {
  a: true,
  b: false,
  c: false,
  d: false,
  e: false,
  f: true
};

function reducer(state, action) {
  return { ...state, [action.name]: !state[action.name] };
}

export const Preferences = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div>
      <Typography
        type="h5"
        font="inter"
        colorStep={100}
        tagName="div"
        // className={style.desc}
      >
        Preferences
      </Typography>
      <div className={style.ref_switches}>
        <div className={style.column}>
          <Switch
            type="def"
            color="success"
            checked={state.a}
            label="Developer preview"
            helperText="Enable awesome features"
            fill
            className={style.switch}
            onChange={() => dispatch({ name: "a" })}
          />
          <Switch
            type="def"
            color="success"
            checked={state.b}
            label="Reduce effects"
            helperText="Optimize your interface speed"
            fill
            className={style.switch}
            onChange={() => dispatch({ name: "b" })}
          />
          <Switch
            type="def"
            color="success"
            checked={state.c}
            label="Display full names"
            helperText="Show users’ full names"
            fill
            className={style.switch}
            onChange={() => dispatch({ name: "c" })}
          />
        </div>
        <div className={style.column}>
          <Switch
            type="def"
            color="success"
            checked={state.d}
            label="Reduce effects"
            helperText="Optimize an interface speed"
            fill
            onChange={() => dispatch({ name: "d" })}
          />
          <Switch
            type="def"
            color="success"
            checked={state.e}
            label="Auto-assign to self"
            helperText="Assign the issue to yourself"
            fill
            onChange={() => dispatch({ name: "e" })}
          />
          <Switch
            type="def"
            color="success"
            checked={state.f}
            label="Accelerate develop"
            helperText="Enable this kit to save time"
            fill
            onChange={() => dispatch({ name: "f" })}
          />
        </div>
      </div>
    </div>
  );
};
