import style from "./style.module.css";
import Tabs from "../../../../core/Tabs/Segmented";
import SearchInput from "../../../../core/Inputs/SearchInput";
import { Tab } from "@blueprintjs/core";
import { useDevice } from "../../../customHooks";

export const TabsLayout = () => {
  let device = useDevice();
  const flexDirection = device === "mobile" ? "column" : "row";
  const alignItems = device === "mobile" ? "flex-start" : "initial";

  return (
    <div className={style.container} style={{ flexDirection, alignItems }}>
      <Tabs view="outlined" color="default" id="DemoTabs">
        <Tab id="1" title="Pools" panel="" />
        <Tab id="2" title="Tokens" panel="" />
        <Tab id="3" title="Governance" panel="" />
      </Tabs>
      <div>
        <SearchInput
          type="def"
          view="outlined"
          color="default"
          value=""
          placeholder="Search tokens"
          fill={device === "mobile"}
          style={device !== "mobile" && { width: "250px" }}
        />
      </div>
    </div>
  );
};
