import React from "react";

import Menu from "../../../../core/Menu";
import MenuItem from "../../../../core/Menu/MenuItem";
import MenuDivider from "../../../../core/Menu/MenuDivider";
import style from "./style.module.css";

export const DropdownMenu = () => (
  <Menu view="outlined" color="default" className={style.menu}>
    <MenuItem view="outlined" color="default" text="PayPal" disabled />
    <MenuItem view="outlined" color="default" text="Credit or Debit Card" />
    <MenuDivider view="outlined" color="default" />
    <MenuItem view="outlined" color="default" text="Invoice" disabled />
  </Menu>
);
