import { Title } from "./Title";
import { Cards } from "./Cards";
import { PieChartLayout } from "./PieChart";
import { BarChart } from "./BarChart";

export const Dashboard = () => {
  return (
    <div style={{ marginBottom: "6rem" }}>
      <Title />
      <Cards />
      <BarChart />
      <PieChartLayout />
    </div>
  );
};
