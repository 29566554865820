import React from "react";
import Typography from "../../../../core/Typography";
import Checkbox from "../../../../core/CheckBox";
import Input from "../../../../core/Inputs/DefaultInput";

import { useEffect, useRef, useState } from "react";
import style from "./style.module.css";

export const Inputs = () => {
  const inputRef = useRef();
  const [count, setCount] = useState(0);
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    const str = String(inputRef?.current?.props.value)?.length;
    setCount(str);
  }, []);

  const handleCount = e => {
    setCount(String(e.target.value).length);
  };
  return (
    <>
      <Input
        ref={inputRef}
        type="text"
        view="outlined"
        color="default"
        label="Email"
        value="johndoe@gma"
        placeholder="Email"
        clearButton
        fill
        onChange={handleCount}
      />
      <Typography
        type="caption"
        font="inter"
        colorStep={50}
        tagName="div"
        className={style.symbol_counter}
        style={count > 110 ? { color: "var(--red60)", fontWeight: "bold" } : {}}
      >
        {count}/110
      </Typography>
      <Input
        type="password"
        view="outlined"
        color="default"
        label="Password"
        value="12345"
        placeholder="Password"
        clearButton
        fill
      />
      <div className={style.additional_line}>
        <Checkbox
          type="dense"
          color="default"
          checked={isChecked}
          label="Remember me"
          onChange={() => setChecked(!isChecked)}
        />
        <a href="#1" style={{ color: "var(--blue60)" }}>
          <Typography
            type="caption"
            font="inter"
            color="primary"
            colorStep={60}
            tagName="div"
          >
            Reset password
          </Typography>
        </a>
      </div>
    </>
  );
};
