import React from "react";
import { Preferences } from "./Preferences";
import style from "../style.module.css";
import { GoPro } from "./GoPro";
import { Notifi } from "./Notifi";
import { TabsDemo } from "./Tabs/Tabs";

export const Section = () => {
  return (
    <div className={style.section}>
      <TabsDemo />
      <Notifi />
      <GoPro />
      <Preferences />
    </div>
  );
};
