import Card from "../../../../core/Card";
import Typography from "../../../../core/Typography";
import { Icon } from "@blueprintjs/core";
import cx from "classnames";
import style from "./style.module.css";
import Color from "../../../../styles/color.module.css";

export const Item = ({ title, icon, color = "default", ...props }) => {
  return (
    <Card view="smooth" interactive {...props}>
      <div style={{ margin: "1rem", display: "inline-block" }}>
        <div className={cx(style.item__icon, Color[color])}>
          <Icon icon={icon} iconSize={40} />
        </div>
        <Typography
          type="h6"
          large
          style={{
            paddingBottom: "1rem"
          }}
          colorStep={100}
        >
          {title}
        </Typography>
        <Typography colorStep={80}>
          Type here a description of General section or helpful user information
        </Typography>
      </div>
    </Card>
  );
};
