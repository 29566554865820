import React, { useEffect, useState } from "react";
import Table from "../../../../core/Table/Table";
import Button from "../../../../core/Button";
import { getCoinsData } from "../api";
import style from "./style.module.css";
import { MarketCap, PriceUsd, CapPerc24, PricePerc24, PriceBtc } from "./Cells";
import Spinner from "../../../../core/Spinner";
import Dialog from "../../../../core/Dialog";
import { useDevice } from "../../../customHooks";
import * as LINKS from "../../../constants/Constants";

export const CoinsTable = () => {
  let device = useDevice();

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const handleClick = e => {
    setOpen(true);
    e.stopPropagation();
  };

  useEffect(() => {
    getCoinsData().then(res => {
      setData(normalize(res));
      setIsLoaded(true);
    });
  }, []);

  const normalize = data => {
    const result = data.map(item => ({
      id: item.id,
      name: item.name,
      thumb: (
        <div style={{ display: "flex" }}>
          <img src={item.image.thumb} alt={item.name} />
        </div>
      ),
      market_cap: <MarketCap value={item.market_data?.market_cap?.usd} />,
      price_usd: <PriceUsd value={item.market_data?.current_price?.usd} />,
      cap_perc_24: (
        <CapPerc24
          value={
            item.market_data?.market_cap_change_percentage_24h_in_currency?.usd
          }
          inCurr={item.market_data?.market_cap_change_24h_in_currency?.usd}
        />
      ),
      price_perc_24: (
        <PricePerc24
          value={item.market_data?.price_change_percentage_24h_in_currency?.usd}
          inCurr={item.market_data?.price_change_24h_in_currency?.usd}
        />
      ),
      price_btc: <PriceBtc value={item.market_data?.current_price?.btc} />,
      btn_add: (
        <Button
          type="icon"
          view="raised"
          color="primary"
          icon="plus"
          onClick={handleClick}
        />
      ),
      btn_swap: (
        <Button
          type="icon"
          view="smooth"
          color="default"
          icon="swap-horizontal"
          onClick={handleClick}
        />
      )
    }));
    return result;
  };

  if (!isLoaded) return <Spinner />;

  return (
    <>
      <Table
        type="def"
        view="flat"
        color="default"
        name={Head}
        data={data}
        position={["left", "left", "right", "right", "right", "right", "right"]}
        pages={true}
        labelRowsPerPage="Show rows:"
        rowsPerPageOptions={[10, 20, 50]}
        defRows={10}
        checkboxes={false}
        paperClassName={style.container}
      />
      <Dialog
        view="raised"
        icon="info-sign"
        title="Ready to use templates"
        text={
          "Setproduct Design System is a React-based UI toolkit for the web. Designed and well-organized in Figma. Optimized for building complex data-dense interfaces for desktop and mobile applications. This digital product contains both Figma design files (FIG) and React components (ZIP) and 60 ready-to-use web app templates"
        }
        backdropOpacity={20}
        leftButton={
          <a
            href={LINKS.FIGMA_DEMO_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              type="default"
              view="outlined"
              dense={false}
              onClick={() => setOpen(false)}
              text="Preview in Figma"
            />
          </a>
        }
        rightButton={
          <a
            href={LINKS.BUY_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              type="default"
              view="filled"
              dense={false}
              text="Purchase for $258"
            />
          </a>
        }
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        className={device === "mobile" ? style.dialog : ""}
      />
    </>
  );
};

const Head = [
  { id: "thumb", label: "" },
  { id: "name", label: "Coin" },
  { id: "market_cap", label: "Market cap" },
  { id: "price_usd", label: "Price" },
  { id: "cap_perc_24", label: "Market cap change 24h" },
  { id: "price_perc_24", label: "Price change 24h" },
  { id: "price_btc", label: "Swap to BTC" },
  { id: "btn_add", label: "" },
  { id: "btn_swap", label: "" }
];
