import Typography from "../../../../core/Typography";
import { Market } from "./Cells";

export const data = [
  {
    id: 1,
    desc: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ whiteSpace: "nowrap" }}
      >
        Swap BNT for LINK
      </Typography>
    ),
    value: (
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ whiteSpace: "nowrap", fontWeight: "500" }}
      >
        $100,031
      </Typography>
    ),
    coin: <Market value="13,434.3" name="BNT" />,
    ticket: <Market value="3,580.03" name="LINK" />,
    address: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        0x00...899Ac7
      </Typography>
    ),
    time: <Market value="1m" name="ago" />
  },
  {
    id: 2,
    desc: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ whiteSpace: "nowrap" }}
      >
        Swap USDC for BNT
      </Typography>
    ),
    value: (
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ whiteSpace: "nowrap", fontWeight: "500" }}
      >
        $99,480
      </Typography>
    ),
    coin: <Market value="100,000" name="USDC" />,
    ticket: <Market value="13,540.66" name="BNT" />,
    address: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        0x00...899Ac7
      </Typography>
    ),
    time: <Market value="12m" name="ago" />
  },
  {
    id: 3,
    desc: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ whiteSpace: "nowrap" }}
      >
        Swap SNX for BNT
      </Typography>
    ),
    value: (
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ whiteSpace: "nowrap", fontWeight: "500" }}
      >
        $50,857
      </Typography>
    ),
    coin: <Market value="2,916.3 " name="SNX" />,
    ticket: <Market value="6,813.76 " name="BNT" />,
    address: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        0x00...899Ac7
      </Typography>
    ),
    time: <Market value="48m" name="ago" />
  },
  {
    id: 4,
    desc: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ whiteSpace: "nowrap" }}
      >
        Swap RVT for BNT
      </Typography>
    ),
    value: (
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ whiteSpace: "nowrap", fontWeight: "500" }}
      >
        $1,328
      </Typography>
    ),
    coin: <Market value="35,000 " name="RVT" />,
    ticket: <Market value="180.31 " name="BNT" />,
    address: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        0x2F...23cCB0
      </Typography>
    ),
    time: <Market value="1h" name="ago" />
  },
  {
    id: 5,
    desc: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ whiteSpace: "nowrap" }}
      >
        Swap BNT for LINK
      </Typography>
    ),
    value: (
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ whiteSpace: "nowrap", fontWeight: "500" }}
      >
        $101,193
      </Typography>
    ),
    coin: <Market value="13,580.02" name="BNT" />,
    ticket: <Market value="3,623.2" name="LINK" />,
    address: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        0x00...899Ac7
      </Typography>
    ),
    time: <Market value="1h" name="ago" />
  },
  {
    id: 6,
    desc: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ whiteSpace: "nowrap" }}
      >
        Swap BNT for LINK
      </Typography>
    ),
    value: (
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ whiteSpace: "nowrap", fontWeight: "500" }}
      >
        $101,251
      </Typography>
    ),
    coin: <Market value="13,587.84" name="BNT" />,
    ticket: <Market value="3,629.66" name="LINK" />,
    address: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        0x00...899Ac7
      </Typography>
    ),
    time: <Market value="4h" name="ago" />
  },
  {
    id: 7,
    desc: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ whiteSpace: "nowrap" }}
      >
        Swap ETH for BNT
      </Typography>
    ),
    value: (
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ whiteSpace: "nowrap", fontWeight: "500" }}
      >
        $13,588
      </Typography>
    ),
    coin: <Market value="7.19 " name="ETH" />,
    ticket: <Market value="1,810.82 " name="BNT" />,
    address: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        0x00...37A9d2
      </Typography>
    ),
    time: <Market value="12h" name="ago" />
  },
  {
    id: 8,
    desc: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ whiteSpace: "nowrap" }}
      >
        Swap BNT for ROOK
      </Typography>
    ),
    value: (
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ whiteSpace: "nowrap", fontWeight: "500" }}
      >
        $14,178
      </Typography>
    ),
    coin: <Market value="1,902.72 " name="BNT" />,
    ticket: <Market value="42.17 " name="ROOK" />,
    address: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        0xdb...EF5944
      </Typography>
    ),
    time: <Market value="2d" name="ago" />
  },
  {
    id: 9,
    desc: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ whiteSpace: "nowrap" }}
      >
        Swap MATIC for BNT
      </Typography>
    ),
    value: (
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ whiteSpace: "nowrap", fontWeight: "500" }}
      >
        $76,400
      </Typography>
    ),
    coin: <Market value="208,785.64 " name="MATIC" />,
    ticket: <Market value="10,297.16 " name="BNT" />,
    address: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        0x00...899Ac7
      </Typography>
    ),
    time: <Market value="4d" name="ago" />
  },
  {
    id: 10,
    desc: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ whiteSpace: "nowrap" }}
      >
        Swap BNT for MRPH
      </Typography>
    ),
    value: (
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ whiteSpace: "nowrap", fontWeight: "500" }}
      >
        $5,992
      </Typography>
    ),
    coin: <Market value="804.2 " name="BNT" />,
    ticket: <Market value="1,918.75 " name="MRPH" />,
    address: (
      <Typography
        type="body"
        font="inter"
        color="primary_alt"
        colorStep={70}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        0x00...5e9f56
      </Typography>
    ),
    time: <Market value="1W" name="ago" />
  }
];
