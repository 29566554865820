import React from "react";
import Typography from "../../../../core/Typography";
import { Icon } from "@blueprintjs/core";
import style from "../style.module.css";

export const Label = ({ color, text, icon }) => {
  return (
    <div className={style.label}>
      <Typography
        color={color}
        colorStep={70}
        tagName="div"
        className={style.icon}
      >
        <Icon icon={icon} iconSize={16} />
      </Typography>

      <Typography
        type="body"
        small
        font="inter"
        color={color}
        colorStep={100}
        tagName="div"
      >
        {text}
      </Typography>
    </div>
  );
};
