import style from "./style.module.css";

export const Legend = () => {
  return (
    <div className={style.legend}>
      <div>
        <div className={style.dot} style={{ background: "var(--indigo60)" }} />
        <div>ReactJS</div>
        <div className={style.dot} style={{ background: "var(--orange60)" }} />
        <div>Angular</div>
        <div className={style.dot} style={{ background: "var(--green60)" }} />
        <div>XueJS</div>
      </div>
      <div>
        <div className={style.dot} style={{ background: "var(--red60)" }} />
        <div>Svelte</div>
        <div className={style.dot} style={{ background: "var(--teal60)" }} />
        <div>VueJS</div>
      </div>
    </div>
  );
};
