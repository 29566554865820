import React from "react";
import Menu from "../../../../core/Menu";
import MenuItem from "../../../../core/Menu/MenuItem";
import MenuDivider from "../../../../core/Menu/MenuDivider";

export default function ExampleMenu({ setRange, view, dense, color }) {
  const type = dense ? "dense" : "def";
  const viewMenu = view;

  return (
    <Menu type={type} view={viewMenu} color={color}>
      <MenuItem
        type={type}
        view={viewMenu}
        color={color}
        text="Feb 3, 2021 – Mar 3, 2021"
        onClick={e => setRange("Feb 3, 2021 – Mar 3, 2021")}
      />

      <MenuItem
        type={type}
        view={viewMenu}
        color={color}
        text="Jan 1, 2021 – Dec 31, 2021"
        onClick={e => setRange("Jan 1, 2021 – Dec 31, 2021")}
      />
      <MenuDivider type={type} view={viewMenu} color={color} />
      <MenuItem
        type={type}
        view={viewMenu}
        color={color}
        text="All period"
        onClick={e => setRange("All period")}
      />
    </Menu>
  );
}
