import style from "./style.module.css";

const tooltipLayout = (label, value, year, color) => {
  return `<div class="${style.tooltip}" 
                style="--bg: var(--${color}5);
                --color: var(--${color}60);
                --shadow: var(--${color}Shadow2dp);">
                <div class="${style.label}">${label}</div>
                <div class="${style.value}">$${value}</div>
                <div class="${style.year}">${year}</div>
              </div>`;
};

export const data = [
  [
    "Year",
    "Total Sales",
    { type: "string", role: "tooltip", p: { html: true } },
    "Expenses",
    { type: "string", role: "tooltip", p: { html: true } },
    "Gross Profit",
    { type: "string", role: "tooltip", p: { html: true } }
  ],
  [
    "2017",
    11000,
    tooltipLayout("Total Sales", "11,000", "2017", "indigo"),
    3400,
    tooltipLayout("Expenses", "3,400", "2017", "pink"),
    1200,
    tooltipLayout("Gross Profit", "1,200", "2017", "teal")
  ],
  [
    "2018",
    8700,
    tooltipLayout("Total Sales", "8,700", "2018", "indigo"),
    4600,
    tooltipLayout("Expenses", "4,600", "2017", "pink"),
    2500,
    tooltipLayout("Gross Profit", "2,500", "2017", "teal")
  ],
  [
    "2019",
    6600,
    tooltipLayout("Total Sales", "6,600", "2019", "indigo"),
    9200,
    tooltipLayout("Expenses", "9,200", "2017", "pink"),
    3000,
    tooltipLayout("Gross Profit", "3,000", "2017", "teal")
  ],
  [
    "2020",
    10030,
    tooltipLayout("Total Sales", "10,030", "2020", "indigo"),
    5400,
    tooltipLayout("Expenses", "5,400", "2017", "pink"),
    3500,
    tooltipLayout("Gross Profit", "3,500", "2017", "teal")
  ],
  [
    "2021",
    15030,
    tooltipLayout("Total Sales", "15,030", "2021", "indigo"),
    9040,
    tooltipLayout("Expenses", "9,040", "2017", "pink"),
    6500,
    tooltipLayout("Gross Profit", "6,500", "2017", "teal")
  ]
];
export const options = {
  animation: {
    startup: true,
    easing: "out",
    duration: 750
  },
  orientation: "horizontal",
  colors: ["#5C6BC0", "#EC407A", "#069697"],
  legend: { position: "none" },
  tooltip: {
    isHtml: true
  },
  chartArea: { width: "85%", height: "85%" }
};
