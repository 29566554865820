import React from "react";

import Menu from "../../../../core/Menu";
import MenuItem from "../../../../core/Menu/MenuItem";

import style from "./style.module.css";
import US from "./US.svg";
import RU from "./RU.svg";
import GB from "./GB.svg";
import CN from "./CN.svg";

const CHINA = (
  <div className={style.dropdown_item_content}>
    <img src={CN} alt="" className={style.dropdown_flag} />
    China
  </div>
);
const USA = (
  <div className={style.dropdown_item_content}>
    <img src={US} alt="" className={style.dropdown_flag} />
    United States
  </div>
);
const RUS = (
  <div className={style.dropdown_item_content}>
    <img src={RU} alt="" className={style.dropdown_flag} />
    Russian Federation
  </div>
);
const GBR = (
  <div className={style.dropdown_item_content}>
    <img src={GB} alt="" className={style.dropdown_flag} />
    Great Britain
  </div>
);

export const DropdownMenu = ({ setCountry }) => (
  <Menu view="outlined" color="default" className={style.menu}>
    <MenuItem
      view="outlined"
      color="default"
      icon={<img src={US} alt="" className={style.menu_flag} />}
      text="United States"
      onClick={e => setCountry(USA)}
    />
    <MenuItem
      view="outlined"
      color="default"
      icon={<img src={CN} alt="" className={style.menu_flag} />}
      text="China"
      onClick={e => setCountry(CHINA)}
    />
    <MenuItem
      view="outlined"
      color="default"
      icon={<img src={RU} alt="" className={style.menu_flag} />}
      text="Russian Federation"
      onClick={e => setCountry(RUS)}
    />
    <MenuItem
      view="outlined"
      color="default"
      icon={<img src={GB} alt="" className={style.menu_flag} />}
      text="Great Britain"
      onClick={e => setCountry(GBR)}
    />
  </Menu>
);
