export const Head = [
  { id: "product", label: "Product" },
  { id: "views", label: "Views" },
  { id: "sales", label: "Sales" },

  { id: "total", label: "Total" }
];

export const Data = [
  {
    id: 1,
    product: "Figma React UI kit",
    views: `2,568`,
    sales: 168,
    convert: "4.5%",
    total: "$12,568.55"
  },
  {
    id: 2,
    product: "Setproduct Angular",
    views: `1,787`,
    sales: 87,
    convert: "3.2%",
    total: "$7,871.69"
  },
  {
    id: 3,
    product: "Orion Charts kit",
    views: 697,
    sales: 69,
    convert: "2.7%",
    total: "$4,697.02"
  },
  {
    id: 4,
    product: "Material Design",
    views: `1,005`,
    sales: 105,
    convert: `4.4%`,
    total: "$1,005.84"
  },
  {
    id: 5,
    product: "Kama Sutra UI kit",
    views: `1,812`,
    sales: 82,
    convert: `2.6%`,
    total: "$812.00"
  },
  {
    id: 6,
    product: "Levitate 3D kit",
    views: 966,
    sales: 16,
    convert: "1.5%",
    total: "$402.50"
  },
  {
    id: 7,
    product: "Neolex System",
    views: 28,
    sales: 8,
    convert: "0.9%",
    total: "$128.64"
  },
  {
    id: 8,
    product: "Dashboard Temp...",
    views: 296,
    sales: 26,
    convert: "1.8%",
    total: "$96.32"
  },
  {
    id: 9,
    product: "Presentation kit",
    views: 112,
    sales: 2,
    convert: `0.2%`,
    total: `$24.05`
  }
];
