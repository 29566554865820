import React from "react";
import Input from "../../../../core/Inputs/DefaultInput";

import style from "./style.module.css";

export const Inputs = () => {
  return (
    <>
      <Input
        type="text"
        view="outlined"
        color="default"
        label="Card number"
        placeholder="0000 0000 0000 0000"
        value="5848 3218 0000 0000"
        clearButton
        fill
        className={style.text_input}
      />
      <div className={style.card_cvc_wrapper}>
        <Input
          type="text"
          view="outlined"
          color="default"
          label="Expiration date"
          placeholder="MM / YY"
          value="MM / YY"
          clearButton
          fill
          className={style.card__exp}
        />
        <Input
          type="text"
          view="outlined"
          color="default"
          label="Security code"
          placeholder="CVC"
          value="CVC"
          clearButton
          fill
        />
      </div>
      <Input
        type="text"
        view="outlined"
        color="default"
        label="Cardholder’s name"
        placeholder="e.g. John Doe"
        value="John Doe"
        clearButton
        fill
        className={style.text_input}
      />
      <Input
        type="text"
        view="outlined"
        color="default"
        label="Billing Zip/Postal code"
        placeholder="e.g. 90210"
        value="90210"
        clearButton
        fill
        className={style.text_input_zip}
      />
    </>
  );
};
