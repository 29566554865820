import React from "react";
//import cx from "classnames";
import Typo from "../../../core/Typography";
import styles from "./style.module.css";
import * as LINKS from "../../constants/Constants";
import Img01 from "./01-home-min.png";
import Img02 from "./02-dashboard-min.png";
import Img03 from "./03-datagrid-min.png";
import Img04 from "./04-profile-min.png";
import Img05 from "./05-inputs-min.png";
import Button from "../../../core/Button";

const DemoStart = props => {
  React.useEffect(() => {
    document.title = `React Design System 2.0 | React UI kit`;
    document.getElementsByTagName("META")[
      "description"
    ].content = `Ready to use React JS components for save your time.`;
  });

  return (
    <div className={styles.container}>
      <Typo type="h4" className={styles.h4_title}>
        Web app templates
      </Typo>
      <Typo large className={styles.text}>
        Setproduct Design System is additionally equipped with ready-to-use
        admin templates for React & Figma. Starting from version 3.0 we’ve added
        60 adaptive layouts (desktop, tablet and mobile) themed into 3 variants
        each to represent how you can mix colors and styles to create various UI
        design themes. You’re free to use these layouts as it is, or remix to
        fit better for your application. Templates are available in the
        <a
          href={LINKS.BUY_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          PRO version.
        </a>
      </Typo>
      <Typo type="h6" className={styles.h6_title}>
        Home / Start
      </Typo>
      <Typo large className={styles.text}>
        This template is recommended as the starting point, where your users are
        about to begin their in-app journey. Based on cards and wrapped icons
        which you can link with your app base sections to provide easy
        navigation.
      </Typo>
      <div className={styles.item_img_container}>
        <a href="/templates/home" style={{ textDecoration: "none" }}>
          <img className={styles.item_img} src={Img01} alt="Home template" />
        </a>
      </div>

      <Typo type="h6" className={styles.h6_title}>
        Dashboard
      </Typo>
      <Typo large className={styles.text}>
        Based on slightly styled Google charts this template is aimed to
        summarize in-app metrics, such as progress, gains, results, and any
        other values which your end users eligible to have on hand.
      </Typo>
      <div className={styles.item_img_container}>
        <a href="/templates/dashboard" style={{ textDecoration: "none" }}>
          <img
            className={styles.item_img}
            src={Img02}
            alt="Dashboard template"
          />
        </a>
      </div>

      <Typo type="h6" className={styles.h6_title}>
        Data Grid
      </Typo>
      <Typo large className={styles.text}>
        This layout based on tables filled with various content provided withing
        a range of density. Fits for any sort of in-app data arranged into
        fleixible and themed tables: Sales, User/Project management, CRM,
        Financial, etc.
      </Typo>
      <div className={styles.item_img_container}>
        <a href="/templates/data-grid" style={{ textDecoration: "none" }}>
          <img
            className={styles.item_img}
            src={Img03}
            alt="Data Grid template"
          />
        </a>
      </div>

      <Typo type="h6" className={styles.h6_title}>
        Profile / Settings
      </Typo>
      <Typo large className={styles.text}>
        Two-in-one common section for the rest of all apps where your end users
        can manage their preferences or tweak in-app properties.
      </Typo>
      <div className={styles.item_img_container}>
        <a href="/templates/profile" style={{ textDecoration: "none" }}>
          <img className={styles.item_img} src={Img04} alt="Profile template" />
        </a>
      </div>

      <Typo type="h6" className={styles.h6_title}>
        Inputs
      </Typo>
      <Typo large className={styles.text}>
        This section provides ready-to-use combinations of text fields to input
        address, credit card details or lets your end-users register.
      </Typo>
      <div className={styles.item_img_container}>
        <a href="/templates/inputs" style={{ textDecoration: "none" }}>
          <img className={styles.item_img} src={Img05} alt="Inputs template" />
        </a>
      </div>
      <a
        href="/templates/home"
        style={{ textDecoration: "none", marginBottom: "1rem" }}
      >
        <Button
          type="action"
          color="primary"
          view="smooth"
          fill
          text="Live preview"
          icon="key-escape"
          style={{ marginBottom: "1rem" }}
        />
      </a>
    </div>
  );
};

export default DemoStart;
