import React from "react";
import { Title } from "./Title";
import style from "./style.module.css";
import { Sidebar } from "./Sidebar";
import { Section } from "./Section";

export const Profile = () => {
  return (
    <div className={style.wrapper}>
      <Title />
      <div className={style.container}>
        <Sidebar />
        <Section />
      </div>
    </div>
  );
};
