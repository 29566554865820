export const pieOptions = {
  is3D: true,
  slices: [
    {
      color: "#7985CB",
      offset: 0.03,
      textStyle: { fontSize: 26 }
    },
    {
      color: "#FB982E",
      offset: 0.03,
      textStyle: { fontSize: 24 }
    },
    {
      color: "#37833B",
      offset: 0.1,
      textStyle: { fontSize: 20 }
    },
    {
      color: "#C8372D",
      offset: 0.2
    },
    {
      color: "#069697",
      offset: 0.05
    }
  ],

  legend: "none",
  tooltip: {
    isHtml: true
  },
  chartArea: { left: 32, top: 0, width: "85%", height: "90%" },
  fontName: "Roboto"
};
