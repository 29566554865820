import Typography from "../../../../core/Typography";
import { useDevice } from "../../../customHooks";

export const Title = () => {
  let device = useDevice();

  return (
    <div style={{ margin: "1.5rem 1rem" }}>
      <Typography type="h4" font="inter" colorStep={100} tagName="div">
        User profile
      </Typography>
      <Typography
        type={device === "mobile" ? "body" : "h6"}
        font="inter"
        colorStep={50}
        tagName="div"
      >
        Manage anyapp settings easily
      </Typography>
    </div>
  );
};
