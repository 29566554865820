import React, { useState, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; //HashRouter,

import "normalize.css/normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";

import "./@setproduct-ui/styles/setproduct.css";
import "./@setproduct-ui/demo/styles/demo.css";
import AppStyle from "./@setproduct-ui/demo/styles/style.module.css";
import TepmlatesAppStyle from "./@setproduct-ui/template/styles/style.module.css";

/**APP */
import DemoAppBar from "./@setproduct-ui/demo/DemoAppBar.jsx";
import LeftSideBar from "./@setproduct-ui/demo/LeftSidebar.jsx";
import Footer from "./@setproduct-ui/demo/Footer.jsx";
import * as ROUTES from "./@setproduct-ui/demo/constants/Routes";

import { ThemeContext } from "./@setproduct-ui/core/ThemeContext";
import { SidebarStateContext } from "./@setproduct-ui/demo/context/SidebarContext";
import { SidebarContainer } from "./@setproduct-ui/demo/components/SidebarContainer";
import { CustomScrollbar } from "./@setproduct-ui/demo/components/CustomScrollbar";

import { useDevice } from "./@setproduct-ui/demo/components/customHooks";
import TemplatesAppBar from "./@setproduct-ui/template/AppBar";
import TemplatesFooter from "./@setproduct-ui/template/Footer";

const App = () => {
  let device = useDevice();
  const [theme, setTheme] = useState(useContext(ThemeContext));
  const [isOpenSidebar, setOpenSidebar] = useState(
    useContext(SidebarStateContext)
  );
  const isTemplates = window.location.pathname.search(/templates/g) !== -1;
  const DemoAppStyle = isTemplates ? TepmlatesAppStyle : AppStyle;
  return (
    <React.Fragment>
      <ThemeContext.Provider
        value={{ isDark: theme.isDark, setDark: setTheme }}
      >
        <SidebarStateContext.Provider
          value={{
            leftSidebarState: isOpenSidebar.left,
            rightSidebarState: isOpenSidebar.right,
            setSidebarState: setOpenSidebar
          }}
        >
          <Router basename="/">
            {/**CONTAINER */}
            <div className={DemoAppStyle.container}>
              {/**HEADER */}
              <div className={DemoAppStyle.header}>
                {window.location.pathname.search(/templates/g) !== -1 ? (
                  <TemplatesAppBar
                    device={device}
                    setOpenSidebar={setOpenSidebar}
                  />
                ) : (
                  <DemoAppBar device={device} />
                )}
              </div>
              {/**LEFT SIDEBAR */}

              <SidebarContainer left position="left">
                <div className={DemoAppStyle.sidebar} id="left-sidebar">
                  <CustomScrollbar left autoHide={true} autoHideTimeout={2000}>
                    <LeftSideBar />
                  </CustomScrollbar>
                </div>
              </SidebarContainer>

              {/*CONTENT*/}

              <div className={DemoAppStyle.content}>
                <CustomScrollbar autoHide={true} autoHideTimeout={2000}>
                  <Switch>
                    <Route exact path="/" component={ROUTES.DEMO_DOCS_INTRO} />
                    {/**DOCS ROUTES */}
                    <Route
                      path={ROUTES.DOCS_COLORS}
                      component={ROUTES.DEMO_DOCS_COLORS}
                    />
                    <Route
                      path={ROUTES.DOCS_ICONS}
                      component={ROUTES.DEMO_DOCS_ICONS}
                    />
                    <Route
                      path={ROUTES.DOCS_INTRO}
                      component={ROUTES.DEMO_DOCS_INTRO}
                    />
                    <Route
                      path={ROUTES.DOCS_INSTALL}
                      component={ROUTES.DEMO_DOCS_INSTALL}
                    />
                    <Route
                      path={ROUTES.DOCS_IMPLEMENT}
                      component={ROUTES.DEMO_DOCS_IMPLEMENT}
                    />
                    <Route
                      path={ROUTES.DOCS_PRINCIP}
                      component={ROUTES.DEMO_DOCS_PRINCIP}
                    />
                    <Route
                      path={ROUTES.DOCS_TEMPLATE}
                      component={ROUTES.DEMO_DOCS_TEMPLATE}
                    />

                    {/**COMPONENTS ROUTES */}
                    <Route path={ROUTES.BADGE} component={ROUTES.DEMO_BADGE} />
                    <Route
                      path={ROUTES.DROPDOWN}
                      component={ROUTES.DEMO_DROPDOWN}
                    />
                    <Route
                      path={ROUTES.TYPOGRAPHY}
                      component={ROUTES.DEMO_TYPOGRAPHY}
                    />
                    <Route
                      path={ROUTES.BUTTON}
                      component={ROUTES.DEMO_BUTTON}
                    />
                    <Route
                      path={ROUTES.TIME_PICKER}
                      component={ROUTES.DEMO_TIME_PICKER}
                    />
                    <Route
                      path={ROUTES.DATE_PICKER}
                      component={ROUTES.DEMO_DATE_PICKER}
                    />
                    <Route
                      path={ROUTES.DATE_RANGE_PICKER}
                      component={ROUTES.DEMO_DATE_RANGE_PICKER}
                    />
                    <Route
                      path={ROUTES.BUTTON_GROUP}
                      component={ROUTES.DEMO_BUTTON_GROUP}
                    />
                    <Route
                      path={ROUTES.CHECKBOX}
                      component={ROUTES.DEMO_CHECKBOX}
                    />
                    <Route path={ROUTES.RADIO} component={ROUTES.DEMO_RADIO} />
                    <Route
                      path={ROUTES.SWITCH}
                      component={ROUTES.DEMO_SWITCH}
                    />
                    <Route path={ROUTES.CHIPS} component={ROUTES.DEMO_CHIPS} />
                    <Route path={ROUTES.TREE} component={ROUTES.DEMO_TREE} />
                    <Route
                      path={ROUTES.TOOLTIP}
                      component={ROUTES.DEMO_TOOLTIP}
                    />
                    <Route
                      path={ROUTES.CALLOUT}
                      component={ROUTES.DEMO_CALLOUT}
                    />
                    <Route path={ROUTES.MENU} component={ROUTES.DEMO_MENU} />
                    <Route
                      path={ROUTES.PROGRESS_BAR}
                      component={ROUTES.DEMO_PROGRESS_BAR}
                    />
                    <Route
                      path={ROUTES.SLIDER}
                      component={ROUTES.DEMO_SLIDER}
                    />
                    <Route
                      path={ROUTES.SPINNER}
                      component={ROUTES.DEMO_SPINNER}
                    />
                    <Route path={ROUTES.TOAST} component={ROUTES.DEMO_TOAST} />
                    <Route
                      path={ROUTES.NAVBAR}
                      component={ROUTES.DEMO_NAVBAR}
                    />
                    <Route path={ROUTES.TABS} component={ROUTES.DEMO_TABS} />
                    <Route
                      path={ROUTES.DEFAULT_INPUT}
                      component={ROUTES.DEMO_DEFAULT_INPUT}
                    />
                    <Route
                      path={ROUTES.SEARCH_INPUT}
                      component={ROUTES.DEMO_SEARCH_INPUT}
                    />
                    <Route path={ROUTES.TABLE} component={ROUTES.DEMO_TABLE} />
                    <Route path={ROUTES.CARD} component={ROUTES.DEMO_CARD} />
                    <Route
                      path={ROUTES.COUNTERS}
                      component={ROUTES.DEMO_COUNTERS}
                    />
                    <Route
                      path={ROUTES.DRAWER}
                      component={ROUTES.DEMO_DRAWER}
                    />

                    <Route
                      path={ROUTES.BREADCRUMBS}
                      component={ROUTES.DEMO_BREADCRUMBS}
                    />
                    <Route
                      path={ROUTES.DIVIDER}
                      component={ROUTES.DEMO_DIVIDER}
                    />
                    <Route
                      path={ROUTES.TABS_SEGMENTED}
                      component={ROUTES.DEMO_TABS_SEGMENTED}
                    />
                    <Route
                      path={ROUTES.DIALOG}
                      component={ROUTES.DEMO_DIALOG}
                    />
                    <Route
                      path={ROUTES.HAMBURGER}
                      component={ROUTES.DEMO_HAMBURGER}
                    />

                    {/**TEMPLATES ROUTES */}
                    <Route
                      path={ROUTES.TEMP_HOME}
                      component={ROUTES.DEMO_TEMP_HOME}
                    />
                    <Route
                      path={ROUTES.TEMP_DASHBOARD}
                      component={ROUTES.DEMO_TEMP_DASHBOARD}
                    />
                    <Route
                      path={ROUTES.TEMP_GRID}
                      component={ROUTES.DEMO_TEMP_GRID}
                    />
                    <Route
                      path={ROUTES.TEMP_PROFILE}
                      component={ROUTES.DEMO_TEMP_PROFILE}
                    />
                    <Route
                      path={ROUTES.TEMP_INPUTS}
                      component={ROUTES.DEMO_TEMP_INPUTS}
                    />
                  </Switch>
                </CustomScrollbar>
              </div>

              <div className={DemoAppStyle.footer}>
                {isTemplates ? <TemplatesFooter /> : <Footer />}
              </div>
            </div>
          </Router>
        </SidebarStateContext.Provider>
      </ThemeContext.Provider>
    </React.Fragment>
  );
};

export default App;
