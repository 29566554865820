import React from "react";
import Typography from "../../../../core/Typography";

export const Market = ({ value, name }) => {
  return (
    <div style={{ display: "flex" }}>
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        {value}
      </Typography>
      <Typography type="body" font="inter" colorStep={50} tagName="div">
        &nbsp;{name}
      </Typography>
    </div>
  );
};

export const PriceUsd = ({ value }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Typography type="body" font="inter" colorStep={40} tagName="div">
        $
      </Typography>
      &nbsp;
      <Typography
        type="body"
        font="inter"
        colorStep={100}
        tagName="div"
        style={{ fontWeight: "500" }}
      >
        {value}
      </Typography>
    </div>
  );
};

export const PricePerc24 = ({ value, inCurr }) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography
          type="body"
          font="inter"
          color={value > 0 ? "success_alt" : "danger_alt"}
          colorStep={70}
          tagName="div"
          style={{ fontWeight: "500" }}
        >
          {Math.round(value * 100) / 100}
        </Typography>
        <Typography type="body" font="inter" colorStep={40} tagName="div">
          %
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography type="caption" font="inter" colorStep={40} tagName="div">
          In Curr.&nbsp;
        </Typography>
        <Typography
          type="caption"
          font="inter"
          colorStep={100}
          tagName="div"
          style={{ fontWeight: "500" }}
        >
          $ {Math.round(inCurr * 1000) / 1000}
        </Typography>
      </div>
    </>
  );
};

export const CapPerc24 = ({ value, inCurr }) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography
          type="body"
          font="inter"
          color={value > 0 ? "success_alt" : "danger_alt"}
          colorStep={70}
          tagName="div"
          style={{ fontWeight: "500" }}
        >
          {Math.round(value * 100) / 100}
        </Typography>
        <Typography type="body" font="inter" colorStep={40} tagName="div">
          %
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography type="caption" font="inter" colorStep={40} tagName="div">
          In Curr.&nbsp;
        </Typography>
        <Typography
          type="caption"
          font="inter"
          colorStep={100}
          tagName="div"
          style={{ fontWeight: "500" }}
        >
          $ {Math.round(inCurr / 10000000) / 100}B
        </Typography>
      </div>
    </>
  );
};

export const PriceBtc = ({ value }) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography
          type="body"
          font="inter"
          color="primary_alt"
          colorStep={70}
          tagName="div"
          style={{ fontWeight: "500" }}
        >
          {value}
        </Typography>
        <Typography type="body" font="inter" colorStep={40} tagName="div">
          ₿
        </Typography>
      </div>
    </>
  );
};
