import React from "react";
import { Display } from "./Display";
import { Email } from "./Email";
import style from "./style.module.css";

export const Notifi = () => {
  return (
    <div className={style.container}>
      <Email />
      <div className={style.dividerBold} />
      <Display />
    </div>
  );
};
