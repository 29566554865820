import Button from "../../../../core/Button";
import Dropdown from "../../../../core/Dropdown";

import style from "./style.module.css";
import { Caption } from "./Caption";
import { Inputs } from "./Inputs";
import { DropdownMenu } from "./DropdownMenu";
import US from "./US.svg";
import { useState } from "react";
import Dialog from "../../../../core/Dialog";
import * as LINKS from "../../../constants/Constants";
import { useDevice } from "../../../customHooks";

const initialCountry = (
  <div className={style.dropdown_item_content}>
    <img src={US} alt="" className={style.dropdown_flag} />
    United States
  </div>
);
export const Billing = () => {
  const [country, setCountry] = useState(initialCountry);
  let device = useDevice();
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <Caption />

        <div className={style.line} />

        <Inputs />

        <Dropdown
          view="outlined"
          color="default"
          text={country}
          position="bottom-left"
          content={<DropdownMenu setCountry={setCountry} />}
          className={style.dropdown}
        />
        <Button
          // view="flat"
          color="success"
          text="Update profile"
          fill={true}
          icon="tick"
          className={style.button}
          onClick={() => setOpen(true)}
        />
      </div>
      <Dialog
        view="raised"
        icon="info-sign"
        title="Ready to use templates"
        text={
          "Setproduct Design System is a React-based UI toolkit for the web. Designed and well-organized in Figma. Optimized for building complex data-dense interfaces for desktop and mobile applications. This digital product contains both Figma design files (FIG) and React components (ZIP) and 60 ready-to-use web app templates"
        }
        backdropOpacity={20}
        leftButton={
          <a
            href={LINKS.FIGMA_DEMO_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              type="default"
              view="outlined"
              dense={false}
              onClick={() => setOpen(false)}
              text="Preview in Figma"
            />
          </a>
        }
        rightButton={
          <a
            href={LINKS.BUY_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              type="default"
              view="filled"
              dense={false}
              text="Purchase for $258"
            />
          </a>
        }
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        className={device === "mobile" ? style.dialog : ""}
      />
    </div>
  );
};
